import { styleSheet } from '../style-sheet';
import { Image, Text, View } from '@react-pdf/renderer';

export type ProposalDocumentHeaderProps = {
	customer: any,
	project: any,
	kitchen: any,
	date: string,
	pageName: string,
	image: string
}

export const ProposalDocumentPageHeader = ({ customer, project, kitchen, date, pageName, image }: ProposalDocumentHeaderProps) => {
	return (
		<View style={styleSheet.header3Column}>
			<View style={styleSheet.headerSingleColumn}>
				<Image src={image} style={styleSheet.pageHeaderImage} />
			</View>
			<View style={styleSheet.headerSingleColumn}>
				<Image src={`${process.env.REACT_APP_RTA_LOGO_URL}`} style={styleSheet.pageHeaderImage} />
			</View>
			<View style={[styleSheet.headerSingleColumn, styleSheet.pullRight]}>
				<Text style={styleSheet.fontWeightBold}>{customer.firstName} {customer.lastName}</Text>
				<Text>{ kitchen.kitchenLayout } | {Math.floor(kitchen.size.width / 12)}ft Grill Island</Text>
				<Text>Designer: {project[0].designer?.firstName} {project[0].designer?.lastName}</Text>
				<Text>Creation Date: {date}</Text>
				{ pageName !== '' && <Text style={styleSheet.pageName}>{pageName}</Text> }
			</View>
		</View>
	);
};
