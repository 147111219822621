import { styleSheet } from '../../style-sheet';

import { Image, View } from '@react-pdf/renderer';

export type ProposalDocumentSidePanelProps = {
	left: string;
	right: string;
}

export const ProposalDocumentPageSidePanel = ({ left, right }: ProposalDocumentSidePanelProps) => {
	return (
		<View>
			<View style={styleSheet.containerSidePanel}>
				<Image src={left} style={styleSheet.fullImage}/>
				<Image src={right} style={styleSheet.fullImage}/>
			</View>
		</View>
	);
};
