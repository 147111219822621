import { Kitchen } from '@rta/rta-blueprint-library';
import { Document, Font, Page } from '@react-pdf/renderer';

import { fontConfig } from '../font-config';
import { styleSheet } from '../style-sheet';

import { ProposalDocumentPageFooter } from './proposal-document-page-footer';
import { ProposalDocumentPageHeader } from './proposal-document-page-header';
import { ProposalDocumentPanelFooter } from './propsal-document-panel-footer';
import { CustomerState, ProjectState } from '@state';
import { ProposalDocumentPageBackPanel, ProposalDocumentPageCountertop, ProposalDocumentPageFrontPanel, ProposalDocumentPageIntro, ProposalDocumentPageKitchenOveriew, ProposalDocumentPageOverheadLayout, ProposalDocumentPageRevisionLog, ProposalDocumentPageSidePanel } from './pages';

Font.register(fontConfig);

export type ProposalDocumentProps = {
	data: {
		customer: CustomerState;
		project: ProjectState;
		tax: number;
		kitchen: Kitchen | null | undefined;
		date: string;
		images: {
			[key: string]: string;
		}
	};
}

// Create Document Component
export const ProposalDocument = (props: ProposalDocumentProps) => {
	const { customer, kitchen, project, date, images } = props.data;
	let page = 1;

	if (!customer || !kitchen || !project) return null;

	return (<>
		<Document>
			<Page size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
				<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={''} image={images.threed} />
				<ProposalDocumentPageIntro image={images.threed} />
				<ProposalDocumentPageFooter page={page} />
			</Page>
			<Page size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
				<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Kitchen Overview'} image={images.threed} />
				<ProposalDocumentPageKitchenOveriew kitchen={kitchen} />
				<ProposalDocumentPageFooter page={++page} />
			</Page>
			<Page size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
				<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Revision Log'} image={images.threed} />
				<ProposalDocumentPageRevisionLog date={date} project={project} />
				<ProposalDocumentPageFooter page={++page} />
			</Page>
			<Page size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
				<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Overhead Layout'} image={images.threed} />
				<ProposalDocumentPageOverheadLayout image={images.overhead} />
				<ProposalDocumentPageFooter page={++page} />
			</Page>
			{kitchen.design.islands.children.length > 0 && kitchen.design.islands.children.map((island, index) => {
				const frontPanelNotes = [
					'Please Note: This view is the exterior side of the panel',
					'MFG: Please add additional support to back panel'
				];
				const sidePanelNotes = ['Please Note: This view is the exterior side of the panels'];
				const backPanelNotes = ['Please Note: This view is the exterior side of the panel'];
				const countertopNotes = [`Please Note: ${page + 1}” back overhang to account for corner trim pieces`];

				return (<>
					<Page key={`front-${index}`} size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
						<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Front Panel'} image={images.threed} />
						<ProposalDocumentPageFrontPanel image={images[`front-${island.id}`]} />
						<ProposalDocumentPanelFooter notes={frontPanelNotes} page={++page} />
					</Page>
					<Page key={`side-${index}`} size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
						<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Side Panels'} image={images.threed} />
						<ProposalDocumentPageSidePanel left={images[`left-${island.id}`]} right={images[`right-${island.id}`]} />
						<ProposalDocumentPanelFooter notes={sidePanelNotes} page={++page} />
					</Page>
					<Page key={`back-${index}`} size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
						<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Back Panel'} image={images.threed} />
						<ProposalDocumentPageBackPanel image={images[`back-${island.id}`]} />
						<ProposalDocumentPanelFooter notes={backPanelNotes} page={++page} />
					</Page>
					<Page key={`countertop-${index}`} size="LETTER" orientation="landscape" style={styleSheet.proposalPage}>
						<ProposalDocumentPageHeader customer={customer} project={project} date={date} kitchen={kitchen} pageName={'Counertop'} image={images.threed} />
						<ProposalDocumentPageCountertop image={images[`countertop-${island.id}`]} />
						<ProposalDocumentPanelFooter notes={countertopNotes} page={++page} />
					</Page>
				</>
				);
			})}
		</Document>
	</>);
};
