/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
import { FaDownload } from 'react-icons/fa';
import { useToast } from '@chakra-ui/react';

import { AppContext } from '@context';
import { EstimateDocument } from '../../components/documents';
import { lang } from '@lang';
import { pdf } from '@react-pdf/renderer';
import { Kitchen3dController as TdComponentController } from '@rta/rta-blueprint-3d-component';
import { useContext } from 'react';
import { ContentPanelMode, customerState, projectState, selectNavigation, setContentPanelMode, useAppDispatch, useAppSelector } from '@state';
import { Feature, FeatureHoookParams } from '..';

type EstimateFeatureParams = FeatureHoookParams & {
	tax: number;
};

export const useEstimateDownloadFeature = (params: EstimateFeatureParams): Feature<null> => {
	const { tax } = params;
	const toast = useToast();

	const { appState: { customerDesigns: designs }} = useContext(AppContext);
	let controller: TdComponentController | null | undefined = null;

	const appNav = useAppSelector(selectNavigation);
	const customer = useAppSelector(customerState);
	const project = useAppSelector(projectState);
	const dispatch = useAppDispatch();

	const date = new Date();
	const year = date.getFullYear().toString()
		.slice(-2);
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString()
		.padStart(2, '0');
	const hour = date.getHours().toString()
		.padStart(2, '0');
	const minutes = date.getMinutes().toString()
		.padStart(2, '0');
	const dateString = `${year}${month}${day}${hour}${minutes}`;
	const fileName = `${customer.firstName.toLowerCase()}-${customer.lastName.toLowerCase()}-estimate-${dateString}.pdf`;

	const [disabled, tooltip] = (() => {
		if (customer.email === '') return [true, lang.document.tooltip.missingCustomer];
		return [false, lang.document.tooltip.generateEstimate];
	})();

	// wait for Image
	const waitForImage = async () => {
		const isRendering = () => controller?.getImage() === '' || controller?.getImage() === null || controller?.getRendering() === true;
		while (isRendering()) {
			// wait promise	to resolve
			await new Promise((resolve) => setTimeout(resolve, 1000));
		}
		const image = controller?.getImage();
		return image ?? '';
	};

	const handleDocumentDownloadClick = async () => {
		dispatch(setContentPanelMode(ContentPanelMode.ThreeD));

		const design = designs.designs.find((bp) => bp.blueprint.id === appNav.designId) ?? null;
		controller = design?.tdController;

		const	imageData = await waitForImage();
		const EstimateDoc = <EstimateDocument data={ { customer, project, tax, kitchen: controller?.kitchen ?? null, image: imageData } } />;

		const blob = await pdf(EstimateDoc).toBlob();
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = fileName;
		link.click();
	};

	return {
		name: 'estimateDocument',
		label: lang.document.label.downloadEstimate,
		icon: (props = {}) => <FaDownload {...props} />,
		tooltip,
		disabled,
		value: null,
		hidden: false,
		handler: () => {
			handleDocumentDownloadClick();
			toast({
				title: lang.document.message.downloadEstimate,
				status: 'info',
				duration: 2000,
				isClosable: true
			});
		}
	};
};

