type ReleaseInfo = {
	release: string;
	build: string;
	commit: string;
}

const releaseInfo: ReleaseInfo = {
	release: '__RELEASE__',
	build: '20241211.2',
	commit: '45cc58b'
};

export { releaseInfo };

