import { styleSheet } from '../../style-sheet';
import { Text, View } from '@react-pdf/renderer';

export type ProposalDocumentKitchenOverviewProps = {
	kitchen: any
}

export const ProposalDocumentPageKitchenOveriew = ({ kitchen }: ProposalDocumentKitchenOverviewProps) => {
	return (
		<View style={styleSheet.lineHeight}>
			<View style={styleSheet.containerSmall}>
				<Text style={styleSheet.fontWeightBold}>Base: <Text style={styleSheet.fontWeightNormal}>{kitchen.material.surround.displayName}</Text></Text>
				<Text style={styleSheet.fontWeightBold}>Countertop: <Text style={styleSheet.fontWeightNormal}>{kitchen.material.countertop.displayName}</Text></Text>
				<Text style={styleSheet.fontWeightBold}>Fuel Source: <Text style={styleSheet.fontWeightNormal}>{kitchen.fuelchoice}</Text></Text>
			</View>
			<View style={styleSheet.containerSmall}>
				<Text style={styleSheet.fontWeightBold}>Kitchen Layout: <Text style={styleSheet.fontWeightNormal}>{kitchen.kitchenLayout}</Text></Text>
			</View>
			{kitchen.itemizedMsrp.islands.length > 0 && kitchen.itemizedMsrp.islands.map((island: any, index: number) => (
				<View key={index} style={styleSheet.containerXSmall}>
					<Text style={styleSheet.fontWeightNormal}>{island.description}</Text>
				</View>
			))}
			<View style={styleSheet.containerSmall}>
				<Text style={[styleSheet.fontWeightBold, styleSheet.marginBottom]}>Appliance List:</Text>
				<View style={styleSheet.text2Col}>
					{kitchen.itemizedMsrp.items.length > 0 && kitchen.itemizedMsrp.items.map((item: any, index: number) => (
						<Text style={[styleSheet.text2ColItem, styleSheet.pullLeft]} key={index}>{item.sku}:{item.description}</Text>
					))}
				</View>
			</View>
			<View style={styleSheet.containerSmall}>
				<Text style={[styleSheet.fontWeightBold, styleSheet.marginBottom]}>Non-Standard Changes: Qty-3 </Text>
				<View style={styleSheet.spaceLeft}>
					<Text style={styleSheet.fontWeightBold}>Pg. 7: <Text>Back utility panel</Text></Text>
					<Text style={styleSheet.fontWeightBold}>Pg. 7: <Text>Upper vent cutout on back panel removed for countertop seam placement</Text></Text>
					<Text style={styleSheet.fontWeightBold}>Pg. 8: <Text>No back overhang</Text></Text>
				</View>
			</View>
			<View style={styleSheet.containerSmall}>
				<Text style={styleSheet.fontWeightBold}>Kitchen Location: <Text style={styleSheet.fontWeightNormal}>{kitchen.location}</Text></Text>
			</View>
			<View style={styleSheet.containerSmallLast}>
				<Text style={[styleSheet.fontWeightBold, styleSheet.fontColorRed, styleSheet.marginBottom]}>Please Note:</Text>
				<View style={[styleSheet.fontWeightBold, styleSheet.fontColorRed, styleSheet.spaceLeft]}>
					<Text>Panels can expect an 1/8{'"'} variation in size, dimension, and clearances</Text>
					<Text>All panels are shown from the EXTERIOR side of the panel</Text>
				</View>
			</View>
		</View>
	);
};
