import { styleSheet } from '../../style-sheet';

import { Image, View } from '@react-pdf/renderer';

export type ProposalDocumentFrontPanelProps = {
	image: string
}

export const ProposalDocumentPageFrontPanel = ({ image }: ProposalDocumentFrontPanelProps) => {
	return (
		<View style={styleSheet.containerCenter}>
			<Image src={image} style={styleSheet.fullImage}/>
		</View>
	);
};
