/* eslint-disable max-lines-per-function */
/* eslint-disable require-await */
/* eslint-disable max-lines */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-trailing-spaces */
/* eslint-disable max-statements */
import { FaDownload } from 'react-icons/fa';
import { pdf } from '@react-pdf/renderer';
import { useToast } from '@chakra-ui/react';

import { AppContext } from '@context';
import { lang } from '@lang';
import { ProposalDocument } from '@components';
import { useDesign } from '@hooks';
import { ContentPanelMode, customerState, projectState, selectNavigation, setContentPanelMode, useAppDispatch, useAppSelector } from '@state';
import { Feature, FeatureHoookParams } from '..';

import ReactDOM from 'react-dom/client';
import { Kitchen3dController as TdComponentController } from '@rta/rta-blueprint-3d-component';
import { BlueprintDesigner, ComponentController as BpComponentController, DisplayMode } from '@rta/rta-blueprint-component';
import { useContext, useEffect, useState } from 'react';

type ProposalFeatureParams = FeatureHoookParams & {
	tax: number;
};

type svgDimensions = {
	width: number;
	height: number;
	zoomFactor?: number;
};

export const useProposalDownloadFeature = (params: ProposalFeatureParams): Feature<null> => {
	const { tax } = params;

	const [controller] = useDesign<BpComponentController>();
	const [images, setImages] = useState({ threed: '', overhead: '' });

	const customer = useAppSelector(customerState);
	const project = useAppSelector(projectState);
	const dispatch = useAppDispatch();

	const { appState: { customerDesigns: designs }} = useContext(AppContext);
	let tdController: TdComponentController | null | undefined = null;

	const appNav = useAppSelector(selectNavigation);

	const toast = useToast();

	const date = new Date();
	const year = date.getFullYear().toString()
		.slice(-2);
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString()
		.padStart(2, '0');
	const hour = date.getHours().toString()
		.padStart(2, '0');
	const minutes = date.getMinutes().toString()
		.padStart(2, '0');
	const dateString = `${year}${month}${day}${hour}${minutes}`;
	const proposalCreationDate = `${day}/${month}/${year}`;
	const fileName = `${customer.firstName.toLowerCase()}-${customer.lastName.toLowerCase()}-proposal-${dateString}.pdf`;
	
	let ProposalDoc = <></>;
	const designImage: { [key: string]: JSX.Element } = {};

	const inlineExternalResources = async (svgElement: SVGSVGElement, baseUrl: string) => {
		const imagesElement = svgElement.querySelectorAll('image');
		for (const image of imagesElement) {
			const originalHref = image.getAttribute('href') || image.getAttributeNS('http://www.w3.org/1999/xlink', 'href');

			if (originalHref && !originalHref.startsWith('http')) {
				// Concatenate the base URL with the original href
				const newHref = `${baseUrl}${originalHref}`;
				image.setAttribute('href', newHref);

				try {
					// Fetch the image
					const response = await fetch(newHref);
					const blob = await response.blob();

					const reader = new FileReader();
					// Create a data URL from the image

					reader.onload = () => {
					// Set the Base64 data as the href of the image
						image.setAttribute('href', reader.result?.toString() ?? '');
					};
					reader.readAsDataURL(blob);

					// Wait for the reader to finish
					await new Promise((resolve) => (reader.onloadend = resolve));
				} catch (error) {
					console.error('Error fetching image:', error);
				}
			}
		}

		const textElements = svgElement.querySelectorAll('text');
		textElements.forEach((text) => {
			const parent = text.parentElement;
			if (!parent) return;

			if (!text.textContent?.includes('"') || text.textContent.includes('℄')) return;

			let bbox = text.getBBox();
			try {
				bbox = text.getBBox();
				if (bbox.width === 0 || bbox.height === 0) {
					throw new Error('Invalid BBox');
				}
			} catch {
				// Fallback: Create temporary SVG for accurate measurement
				const tempSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
				tempSvg.style.visibility = 'hidden';
				document.body.appendChild(tempSvg);
				const tempText = text.cloneNode(true) as SVGTextElement;
				tempSvg.appendChild(tempText);
				bbox = tempText.getBBox();
				document.body.removeChild(tempSvg);
			}

			const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

			// Set the rect's attributes to match the text's bounding box
			rect.setAttribute('x', (bbox.x - 0.25).toString());
			rect.setAttribute('y', (bbox.y - 0.25).toString());
			rect.setAttribute('width', (bbox.width + 0.5).toString());
			rect.setAttribute('height', (bbox.height + 0.5).toString());
			rect.setAttribute('rx', '0.5');
			rect.setAttribute('ry', '0.75');
			rect.setAttribute('stroke', 'black');
			rect.setAttribute('stroke-width', '0.083');
			rect.setAttribute('fill', 'white');

			// Insert the rect before the text element
			parent.insertBefore(rect, text);
		});

		const ellipseElements = svgElement.querySelectorAll('ellipse');
		ellipseElements.forEach((ellipse) => {
			const parent = ellipse.parentElement;
			if (!parent) return;
			ellipse.setAttribute('rx', '5');
		});

		const viewBox = svgElement.getAttribute('viewBox');
		if (viewBox && viewBox.includes('-Infinity')) {
			svgElement.setAttribute('viewBox', '-105.58333333333334 -65.25 211.16666666666669 130.5');
		}

		return svgElement;
	};

	// wait for Image
	const waitForImage = async () => {
		console.log('tdCOntr', tdController);
		const isRendering = () => tdController?.getImage() === '' || tdController?.getImage() === null || tdController?.getRendering() === true;
		while (isRendering()) {
			// wait promise	to resolve
			await new Promise((resolve) => setTimeout(resolve, 1000));
		}
		const image = tdController?.getImage();
		return image ?? '';
	};
	
	const [disabled, tooltip] = (() => {
		if (customer.email === '') return [true, lang.document.tooltip.missingCustomer];
		if (project.length === 0) return [true, lang.document.tooltip.missingHubspotData];
		return [false, lang.document.tooltip.generateBlueprint];
	})();

	useEffect(() => {
		const downloadPdf = async (doc: any) => {
			const blob = await pdf(doc).toBlob();
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = fileName;
			link.click();

			// Reset Images
			setImages({ threed: '', overhead: '' });
		};

		if (images.threed !== '' && images.overhead !== '') {
			ProposalDoc = <ProposalDocument data={ { customer, project, tax, kitchen: controller?.kitchen, date: proposalCreationDate, images } } />;

			downloadPdf(ProposalDoc);
		}
	}, [images]);

	const handleDocumentDownloadClick = async () => {
		if (controller !== null) {
			for (let j = 0; j < controller.kitchen?.design.islands.length; j++) {
				const island = controller.kitchen?.design.islands.children[j];
				const panels = island.panels.children;

				for (let i = 0; i < panels.length; i++) {
					const bpController = new BpComponentController(controller.blueprint, { eventGroup: `bp1-${controller.blueprint.id}` });
					bpController.navigation.displayMode = DisplayMode.Panel;
					bpController.options.autoZoom = false;
					bpController.zoom = 55;
					
					bpController.navigation.islandId = island.id;
					bpController.navigation.panelId = panels[i].id;
					designImage[`${panels[i].label}-${island.id}`] = <BlueprintDesigner controller={bpController as BpComponentController} backgroundColor={'unset'} />;
				}

				// Get Countertop Image
				const bpController = new BpComponentController(controller.blueprint, { eventGroup: `bp1-${controller.blueprint.id}` });
				bpController.navigation.displayMode = DisplayMode.Panel;
				bpController.options.autoZoom = false;
				bpController.zoom = 55;
				bpController.navigation.islandId = island.id;
				bpController.navigation.panelId = null;
				designImage[`countertop-${island.id}`] = <BlueprintDesigner controller={bpController as BpComponentController} backgroundColor={'unset'} />;
			}

			// Get Overhead Image
			const bpControllerOverhead = new BpComponentController(controller.blueprint, { eventGroup: `bp1-${controller.blueprint.id}` });
			bpControllerOverhead.navigation.displayMode = DisplayMode.Overhead;
			bpControllerOverhead.options.autoZoom = true;
			designImage.overhead = <BlueprintDesigner controller={bpControllerOverhead as BpComponentController} backgroundColor={'unset'} />;
			
			const generateSvgImage = async (container: any, key: any, dimensions: svgDimensions) => {
				return new Promise((resolve) => {
					setTimeout(async () => {
						if (container.firstChild !== null) {
							const svg = container.querySelector('svg');
							if (svg === null) return;
			
							const ss = await inlineExternalResources(svg, process.env.REACT_APP_ASSETS_BASE_URL ?? '');
							const svgElement = new XMLSerializer().serializeToString(ss as Node);
							const image = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgElement)))}`;
							
							const img = new Image();
							img.onload = () => {
								const canvas = document.createElement('canvas');
								canvas.width = dimensions.width;
								canvas.height = dimensions.height;
								const ctx = canvas.getContext('2d');

								if (!ctx) return;
								ctx.clearRect(0, 0, dimensions.width, dimensions.height);

								const zoomFactor = dimensions.zoomFactor ?? 1;
								const zoomedWidth = dimensions.width * zoomFactor;
								const zoomedHeight = key === 'overhead' ? 900 * zoomFactor : 1500 * zoomFactor;

								const offsetX = (canvas.width - zoomedWidth) / 2; 
								const offsetY = (canvas.height - zoomedHeight) / 2; 

								ctx.drawImage(img, offsetX, offsetY, zoomedWidth, zoomedHeight);
								const pngDataUrl = canvas.toDataURL('image/png');
								resolve(pngDataUrl);
							};
							img.src = image;
						}
					}, 500);
				});
			};

			const processImages = async () => {
				const newImages: { [key: string]: unknown } = {};
				for (let i = 0; i < Object.keys(designImage).length; i++) {
					const container = document.createElement('div');
					const root = ReactDOM.createRoot(container);
					const key = Object.keys(designImage)[i];

					root.render(designImage[key]);
					const keys = key.split('-');
					const islandId = keys.slice(1).join('-');

					const width = controller.kitchen?.design.islands.findById(islandId)?.islandSize.width;
					let zoomFactor = 1.2;

					if (width && width > 50) {
						zoomFactor = 1;
					}

					if (key === 'overhead') zoomFactor = 1.45;

					const imageData = await generateSvgImage(container, key, { width: key === 'overhead' ? 1200 : 1500, height: key === 'overhead' ? 900 : 1200, zoomFactor });
					newImages[key] = imageData; 
				}
				setImages((prevImages) => ({ ...prevImages, ...newImages })); 
			};

			processImages();

			dispatch(setContentPanelMode(ContentPanelMode.ThreeD));
			const design = designs.designs.find((bp) => bp.blueprint.id === appNav.designId) ?? null;
			tdController = design?.tdController;
			const	imageData = await waitForImage();

			setImages({ ...images, threed: imageData });
		}
	};

	return {
		name: 'proposalDocument',
		label: lang.document.label.downloadBlueprint,
		icon: (props = {}) => <FaDownload {...props} />,
		tooltip,
		disabled,
		value: null,
		hidden: false,
		handler: () => {
			handleDocumentDownloadClick();
			toast({
				title: lang.document.message.downloadBlueprint,
				status: 'info',
				duration: 2000,
				isClosable: true
			});
		}
	};
};
