import { styleSheet } from '../../style-sheet';

import { Image, View } from '@react-pdf/renderer';

export type ProposalDocumentCountertopProps = {
	image: string;
}

export const ProposalDocumentPageCountertop = ({ image }: ProposalDocumentCountertopProps) => {
	return (
		<View>
			<View style={styleSheet.containerCenter}>
				<Image src={image} style={styleSheet.fullImage}/>
			</View>
		</View>
	);
};
