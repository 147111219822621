import { styleSheet } from '../../style-sheet';
import { Image, Text, View } from '@react-pdf/renderer';

export type ProposalDocumentIntroProps = {
	image: string
}

export const ProposalDocumentPageIntro = ({ image }: ProposalDocumentIntroProps) => {
	return (
		<View>
			<View>
				<Text style={styleSheet.pageIntroHeadingRed}>
					This is a computer-generated rendering of your island.
				</Text>
				<Text style={styleSheet.pageIntroHeadingRed}>
					Countertop and panel colors vary, and actual color may not match photo.
				</Text>
				<Text style={styleSheet.pageIntroHeadingRed}>
					Please verify countertop and panel finishes with your designer prior to approving your blueprints.
				</Text>
			</View>
			<View style={styleSheet.containerCenter}>
				<Image src={image} style={styleSheet.pageIntroHeroImage}/>
			</View>
			<View style={[styleSheet.containerCenter, styleSheet.orangeBox]}>
				<Text style={styleSheet.marginBottom}>
					Coyote grills & cooking appliances will require 24” of clearance from combustible materials (in any direction).
				</Text>
				<Text>
					You cannot locate any Coyote grill or cooking appliance under any combustible material (pergolas, pavilions, etc.) without an approved ventilations hood.
					These requirements are specific to Coyote appliances so if you are using other appliances,
					you must refer to that manufacturer’s owner’s manual for more details.
				</Text>
			</View>
		</View>
	);
};
