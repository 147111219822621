import { styleSheet } from '../../style-sheet';
import { Image, View } from '@react-pdf/renderer';

export type ProposalDocumentPageOverheadLayoutProps = {
	image: string;
}

export const ProposalDocumentPageOverheadLayout = ({ image }: ProposalDocumentPageOverheadLayoutProps) => {
	return (
		<View style={styleSheet.containerCenter}>
			<Image src={image} style={styleSheet.overheadImage} />
		</View>
	);
};
